<template>
  <div>
    <PageTitle
      :title="$t('listing.job_applications.title')"
      icon="mdi-account-group"
    >
      <template v-slot:actions>
        <v-btn color="primary" @click="createJobApplication">
          {{ $t('listing.job_applications.create') }}
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>
    </PageTitle>
    <JobApplicationsList
      @show-job-application-details="jobApplicationDetails"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import JobApplicationsList from '@/components/jobApplications/JobApplicationsList.vue'
import PageTitle from '@/components/layout/PageTitle.vue'
import RouterMixin from '@/mixins/routerMixin'

export default {
  components: { PageTitle, JobApplicationsList },
  mixins: [RouterMixin],
  methods: {
    ...mapActions('candidates', ['unselectCandidate']),
    ...mapActions('page', ['setDataIsLoading']),
    createJobApplication() {
      this.unselectCandidate()
      this.routerPush('/jobApplications/details')
    },
    jobApplicationDetails(jobApplicationId) {
      this.routerPush(`/jobApplications/${jobApplicationId}/details`)
    }
  }
}
</script>
